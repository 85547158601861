<template>
    <div>
        <b-card :title="$t('labels.games-promotion-management') || 'Gestión de Juegos en Promoción'">
            <b-card-text>
                <p class="text-muted mb-3">
                    <feather-icon icon="InfoIcon" size="16" class="mr-1" />
                    {{ $t('labels.promotion-explanation') || `Gestione los juegos que aparecerán en promoción.
                    Seleccione un proveedor y opcionalmente una marca para filtrar.` }}
                </p>

                <!-- Filtros de producto y marca -->
                <b-row class="mb-3">
                    <b-col md="6">
                        <label>{{ $t("labels.supplier") }} <span class="text-danger">*</span></label>
                        <b-overlay :show="loadingProducts" rounded="lg" opacity="0.6">
                            <template v-slot:overlay>
                                <div class="d-flex align-items-center">
                                    <b-spinner small type="grow" variant="secondary" />
                                    <b-spinner type="grow" variant="dark" />
                                    <b-spinner small type="grow" variant="secondary" />
                                    <span class="sr-only">{{ $t("please-wait") }}</span>
                                </div>
                            </template>

                            <v-select class="mb-1" v-model="selectedProduct"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="productsOptions"
                                label="name" @input="productSelected" :reduce="(product) => product._id"
                                :placeholder="$t('labels.select-product')">
                            </v-select>
                        </b-overlay>
                    </b-col>

                    <b-col md="6">
                        <label>{{ $t("brands") }}</label>
                        <b-overlay :show="loadingBrands" rounded="lg" opacity="0.6">
                            <template v-slot:overlay>
                                <div class="d-flex align-items-center">
                                    <b-spinner small type="grow" variant="secondary" />
                                    <b-spinner type="grow" variant="dark" />
                                    <b-spinner small type="grow" variant="secondary" />
                                    <span class="sr-only">{{ $t("please-wait") }}</span>
                                </div>
                            </template>

                            <v-select class="mb-1" v-model="selectedBrand"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="brandsOptions"
                                label="name" :reduce="(brand) => brand._id" @input="brandSelected"
                                :disabled="!selectedProduct" :placeholder="$t('labels.select-brand')">
                            </v-select>
                        </b-overlay>
                    </b-col>
                </b-row>

                <!-- Información de carga -->
                <b-overlay :show="loadingPromoGames" rounded="lg" opacity="0.6" class="w-100">
                    <template v-slot:overlay>
                        <div class="d-flex align-items-center">
                            <b-spinner small type="grow" variant="secondary" />
                            <b-spinner type="grow" variant="dark" />
                            <b-spinner small type="grow" variant="secondary" />
                            <span class="sr-only">{{ $t("please-wait") }}</span>
                        </div>
                    </template>

                    <!-- Contenido de las listas de juegos -->
                    <b-row>
                        <!-- Lista de juegos no promocionados -->
                        <b-col md="6">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <h5>
                                    {{ $t('labels.games-not-in-promotion') || 'Juegos no promocionados' }}
                                    <b-badge variant="dark" class="ml-1">{{ totalNonPromoGames }}</b-badge>
                                </h5>
                                <b-button v-if="selectedProduct" variant="outline-primary" size="sm"
                                    @click="addAllToPromotion" :disabled="isUpdatingAll || nonPromoGames.length === 0">
                                    <template v-if="isUpdatingAll">
                                        <b-spinner small></b-spinner>
                                        {{ $t('labels.adding-all') || 'Agregando todos...' }}
                                    </template>
                                    <template v-else>
                                        <feather-icon icon="PlusIcon" size="14" class="mr-1" />
                                        {{ $t('labels.add-all-to-promo') || 'Agregar todos a promoción' }}
                                    </template>
                                </b-button>
                            </div>

                            <b-input-group class="mb-2">
                                <b-form-input v-model="searchNonPromo"
                                    :placeholder="$t('labels.search-games') || 'Buscar juegos'"
                                    @keyup.enter="searchNonPromoGames"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-secondary" @click="searchNonPromoGames">
                                        <feather-icon icon="SearchIcon" size="16" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <div class="game-list-container">
                                <b-list-group class="game-list">
                                    <b-list-group-item v-for="game in nonPromoGames" :key="game._id"
                                        class="d-flex justify-content-between align-items-center game-item">
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <b-img :src="game.url_image" width="40" height="40" class="mr-2"
                                                rounded="sm"></b-img>
                                            <div>
                                                <div>{{ game.gameName }}</div>
                                                <div class="game-metadata">
                                                    <b-badge pill variant="primary" class="mr-1 metadata-badge">
                                                        {{ game.product ? game.product.name : '-' }}
                                                    </b-badge>
                                                    <b-badge pill variant="secondary" class="metadata-badge">
                                                        {{ game.brandName || '-' }}
                                                    </b-badge>
                                                </div>
                                            </div>
                                        </div>
                                        <b-button variant="outline-primary" size="sm" @click="addToPromotion(game)"
                                            :disabled="updatingGame === game._id">
                                            <template v-if="updatingGame === game._id">
                                                <b-spinner small></b-spinner>
                                            </template>
                                            <template v-else>
                                                <feather-icon icon="ArrowRightIcon" size="16" />
                                            </template>
                                        </b-button>
                                    </b-list-group-item>
                                    <b-list-group-item v-if="nonPromoGames.length === 0" class="text-center text-muted">
                                        {{ $t('labels.no-games-found') || 'No se encontraron juegos' }}
                                    </b-list-group-item>
                                </b-list-group>
                            </div>

                            <!-- Paginación para juegos no promocionados -->
                            <section style="padding: 20px 0 0px 0px">
                                <b-row>
                                    <b-col cols="12">
                                        <b-pagination v-model="nonPromoCurrPage" :total-rows="totalNonPromoGames"
                                            :per-page="perPage" first-number @change="getNonPromoGames" align="right"
                                            last-number prev-class="prev-item" next-class="next-item">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </b-col>
                                </b-row>
                            </section>
                        </b-col>

                        <!-- Lista de juegos en promoción -->
                        <b-col md="6">
                            <div class="d-flex justify-content-between align-items-center mb-2">
                                <h5>
                                    {{ $t('labels.games-in-promotion') || 'Juegos en promoción' }}
                                    <b-badge variant="primary" class="ml-1">{{ totalPromoGames }}</b-badge>
                                </h5>
                                <b-button v-if="selectedProduct" variant="outline-danger" size="sm"
                                    @click="removeAllFromPromotion"
                                    :disabled="isUpdatingAll || promoGames.length === 0">
                                    <template v-if="isUpdatingAll">
                                        <b-spinner small></b-spinner>
                                        {{ $t('labels.removing-all') || 'Quitando todos...' }}
                                    </template>
                                    <template v-else>
                                        <feather-icon icon="MinusIcon" size="14" class="mr-1" />
                                        {{ $t('labels.remove-all-from-promo') || 'Quitar todos de promoción' }}
                                    </template>
                                </b-button>
                            </div>

                            <b-input-group class="mb-2">
                                <b-form-input v-model="searchPromo"
                                    :placeholder="$t('labels.search-promo-games') || 'Buscar juegos promocionados'"
                                    @keyup.enter="searchPromoGames"></b-form-input>
                                <b-input-group-append>
                                    <b-button variant="outline-secondary" @click="searchPromoGames">
                                        <feather-icon icon="SearchIcon" size="16" />
                                    </b-button>
                                </b-input-group-append>
                            </b-input-group>

                            <div class="game-list-container">
                                <b-list-group class="game-list">
                                    <b-list-group-item v-for="game in promoGames" :key="game._id"
                                        class="d-flex justify-content-between align-items-center game-item">
                                        <div class="d-flex align-items-center flex-grow-1">
                                            <b-img :src="game.url_image" width="40" height="40" class="mr-2"
                                                rounded="sm"></b-img>
                                            <div>
                                                <div>{{ game.gameName }}</div>
                                                <div class="game-metadata">
                                                    <b-badge pill variant="primary" class="mr-1 metadata-badge">
                                                        {{ game.product ? game.product.name : '-' }}
                                                    </b-badge>
                                                    <b-badge pill variant="secondary" class="metadata-badge">
                                                        {{ game.brandName || '-' }}
                                                    </b-badge>
                                                </div>
                                            </div>
                                        </div>
                                        <b-button variant="outline-danger" size="sm" @click="removeFromPromotion(game)"
                                            :disabled="updatingGame === game._id">
                                            <template v-if="updatingGame === game._id">
                                                <b-spinner small></b-spinner>
                                            </template>
                                            <template v-else>
                                                <feather-icon icon="ArrowLeftIcon" size="16" />
                                            </template>
                                        </b-button>
                                    </b-list-group-item>
                                    <b-list-group-item v-if="promoGames.length === 0" class="text-center text-muted">
                                        {{ $t('labels.no-promo-games-found') || 'No hay juegos en promoción' }}
                                    </b-list-group-item>
                                </b-list-group>
                            </div>

                            <!-- Paginación para juegos promocionados -->
                            <section style="padding: 20px 0 0px 0px">
                                <b-row>
                                    <b-col cols="12">
                                        <b-pagination v-model="promoCurrPage" :total-rows="totalPromoGames"
                                            :per-page="perPage" first-number @change="getPromoGames" align="right"
                                            last-number prev-class="prev-item" next-class="next-item">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </b-col>
                                </b-row>
                            </section>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BCol,
    BButton,
    BCardText,
    BRow,
    BOverlay,
    BSpinner,
    BAlert,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BPagination
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import store from "@/store";
import productStoreModule from "@/store/products";
import gamesStoreModule from "@/store/games";

export default {
    components: {
        BCard,
        BButton,
        BCardText,
        BCol,
        BRow,
        BOverlay,
        BSpinner,
        BAlert,
        BListGroup,
        BListGroupItem,
        BFormInput,
        BImg,
        BInputGroup,
        BInputGroupAppend,
        BBadge,
        BPagination,
        vSelect,
        ToastificationContent,
    },
    data() {
        return {
            selectedProduct: null,
            selectedBrand: null,
            productsOptions: [],
            brandsOptions: [],
            loadingProducts: false,
            loadingBrands: false,

            // Variables para gestión de promociones
            promoGames: [],
            nonPromoGames: [],
            searchPromo: '',
            searchNonPromo: '',
            loadingPromoGames: false,
            updatingGame: null,
            isUpdatingAll: false,

            // Variables para paginación
            perPage: 20,
            promoCurrPage: 1,
            nonPromoCurrPage: 1,
            totalPromoGames: 0,
            totalNonPromoGames: 0
        };
    },
    methods: {
        async loadProducts() {
            try {
                this.loadingProducts = true;
                await this.$store.dispatch("productsModule/getProducts");
                this.productsOptions = this.$store.getters["productsModule/productsActives"];
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando productos",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingProducts = false;
            }
        },

        async productSelected(productId) {
            if (productId) {
                this.selectedBrand = null;
                // Cargar las marcas del producto

                // Resetear paginación
                this.promoCurrPage = 1;
                this.nonPromoCurrPage = 1;
                // Cargar juegos por separado según su estado de promoción
                await Promise.all([
                    this.loadBrands(productId),
                    this.getNonPromoGames(),
                    this.getPromoGames()
                ]);
            } else {
                this.brandsOptions = [];
                this.selectedBrand = null;
                this.clearPromotionLists();
            }
        },

        async loadBrands(productId) {
            try {
                if (!productId) return;

                this.loadingBrands = true;
                const response = await this.$store.dispatch("brandModule/index", productId);
                this.brandsOptions = response.data.brands;
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando marcas",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingBrands = false;
            }
        },

        async brandSelected(brandId) {
            // Resetear paginación
            this.promoCurrPage = 1;
            this.nonPromoCurrPage = 1;
            // Cargar juegos con el nuevo filtro de marca
            await Promise.all([
                this.getNonPromoGames(),
                this.getPromoGames()
            ]);
        },

        // Métodos para gestión de promociones con paginación
        async getNonPromoGames(page = null) {
            try {
                this.loadingPromoGames = true;

                const params = {
                    page: page || this.nonPromoCurrPage,
                    perPage: this.perPage,
                    currentPromotion: false, // Filtro para juegos no promocionados
                    q: this.searchNonPromo || undefined
                };

                if (this.selectedBrand) {
                    const selectedBrandObj = this.brandsOptions.find(b => b._id === this.selectedBrand);
                    if (selectedBrandObj) {
                        params.brandId = selectedBrandObj.brandId;
                    }
                }

                const response = await this.$store.dispatch("gamesModule/getGames", {
                    producSelectId: this.selectedProduct || 'not',
                    params
                });

                this.nonPromoGames = response.data.games;
                this.totalNonPromoGames = response.data.count;
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando juegos no promocionados",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingPromoGames = false;
            }
        },

        async getPromoGames(page = null) {
            try {
                this.loadingPromoGames = true;

                const params = {
                    page: page || this.promoCurrPage,
                    perPage: this.perPage,
                    currentPromotion: true, // Filtro para juegos promocionados
                    q: this.searchPromo || undefined
                };

                if (this.selectedBrand) {
                    const selectedBrandObj = this.brandsOptions.find(b => b._id === this.selectedBrand);
                    if (selectedBrandObj) {
                        params.brandId = selectedBrandObj.brandId;
                    }
                }

                const response = await this.$store.dispatch("gamesModule/getGames", {
                    producSelectId: this.selectedProduct || 'not',
                    params
                });

                this.promoGames = response.data.games;
                this.totalPromoGames = response.data.count;
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error cargando juegos promocionados",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                    },
                });
            } finally {
                this.loadingPromoGames = false;
            }
        },

        searchNonPromoGames() {
            this.nonPromoCurrPage = 1;
            this.getNonPromoGames(1);
        },

        searchPromoGames() {
            this.promoCurrPage = 1;
            this.getPromoGames(1);
        },

        clearPromotionLists() {
            this.promoGames = [];
            this.nonPromoGames = [];
            this.searchPromo = '';
            this.searchNonPromo = '';
            this.totalPromoGames = 0;
            this.totalNonPromoGames = 0;
            this.promoCurrPage = 1;
            this.nonPromoCurrPage = 1;
        },

        async addToPromotion(game) {
            try {
                this.updatingGame = game._id;

                // Llamar al API para actualizar el estado del juego
                await this.$store.dispatch("bonus/updateAllGamesPromotion", {
                    gameId: game._id,
                    currentPromotion: true
                });

                // Recargar ambas listas para reflejar el cambio
                await Promise.all([
                    this.getNonPromoGames(),
                    this.getPromoGames()
                ]);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Juego añadido a promociones",
                        icon: "CheckCircleIcon",
                        variant: "success",
                    },
                });
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error al añadir juego a promociones",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: error.response?.data?.message || "No se pudo actualizar el estado de promoción",
                    },
                });
            } finally {
                this.updatingGame = null;
            }
        },

        async removeFromPromotion(game) {
            try {
                this.updatingGame = game._id;

                // Llamar al API para actualizar el estado del juego
                await this.$store.dispatch("bonus/updateAllGamesPromotion", {
                    gameId: game._id,
                    currentPromotion: false
                });

                // Recargar ambas listas para reflejar el cambio
                await Promise.all([
                    this.getNonPromoGames(),
                    this.getPromoGames()
                ]);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Juego eliminado de promociones",
                        icon: "CheckCircleIcon",
                        variant: "success",
                    },
                });
            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error al eliminar juego de promociones",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: error.response?.data?.message || "No se pudo actualizar el estado de promoción",
                    },
                });
            } finally {
                this.updatingGame = null;
            }
        },

        // Nuevos métodos para agregar/quitar todos los juegos de la promoción
        async addAllToPromotion() {
            try {
                this.isUpdatingAll = true;

                // Preparar parámetros para la operación masiva
                const params = {
                    productId: this.selectedProduct,
                    currentPromotion: true
                };

                // Si hay una marca seleccionada, incluirla
                if (this.selectedBrand) {
                    const brandObj = this.brandsOptions.find(b => b._id === this.selectedBrand);
                    if (brandObj) {
                        params.brandId = this.selectedBrand;
                    }
                }

                // Si hay un término de búsqueda, incluirlo
                if (this.searchNonPromo) {
                    params.searchTerm = this.searchNonPromo;
                }

                // Llamar al API para actualizar todos los juegos filtrados
                await this.$store.dispatch("bonus/updateAllGamesPromotion", params);

                // Recargar ambas listas
                await Promise.all([
                    this.getNonPromoGames(),
                    this.getPromoGames()
                ]);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Juegos añadidos a promociones",
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: "Todos los juegos filtrados han sido añadidos a promociones",
                    },
                });

            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error al añadir juegos a promociones",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: error.response?.data?.message || "No se pudieron actualizar los juegos",
                    },
                });
            } finally {
                this.isUpdatingAll = false;
            }
        },

        async removeAllFromPromotion() {
            try {
                this.isUpdatingAll = true;

                // Preparar parámetros para la operación masiva
                const params = {
                    productId: this.selectedProduct,
                    currentPromotion: false
                };

                // Si hay una marca seleccionada, incluirla
                if (this.selectedBrand) {
                    const brandObj = this.brandsOptions.find(b => b._id === this.selectedBrand);
                    if (brandObj) {
                        params.brandId = this.selectedBrand;
                    }
                }

                // Si hay un término de búsqueda, incluirlo
                if (this.searchPromo) {
                    params.searchTerm = this.searchPromo;
                }

                // Llamar al API para actualizar todos los juegos filtrados
                await this.$store.dispatch("bonus/updateAllGamesPromotion", params);

                // Recargar ambas listas
                await Promise.all([
                    this.getNonPromoGames(),
                    this.getPromoGames()
                ]);

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Juegos eliminados de promociones",
                        icon: "CheckCircleIcon",
                        variant: "success",
                        text: "Todos los juegos filtrados han sido eliminados de promociones",
                    },
                });

            } catch (error) {
                console.error(error);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error al eliminar juegos de promociones",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                        text: error.response?.data?.message || "No se pudieron actualizar los juegos",
                    },
                });
            } finally {
                this.isUpdatingAll = false;
            }
        }
    },
    async mounted() {
        await Promise.all([
            this.loadProducts(),
            this.getNonPromoGames(),
            this.getPromoGames()
        ]);
    },
    created() {
        // Register module if not registered
        if (!store.hasModule("productsModule")) {
            store.registerModule("productsModule", productStoreModule);
        }

        if (!store.hasModule("gamesModule")) {
            store.registerModule("gamesModule", gamesStoreModule);
        }
    },
};
</script>

<style scoped>
.mt-3 {
    margin-top: 1rem;
}

.game-list-container {
    height: 600px;
    overflow-y: auto;
    border: 1px solid #eee;
    border-radius: .5rem;
}

.game-list {
    height: 100%;
}

.game-item {
    cursor: pointer;
    transition: background-color 0.2s;
}

.game-item:hover {
    background-color: #f8f9fa;
}

.prev-item,
.next-item {
    transform: translateY(5px);
}
</style>